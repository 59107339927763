<template>
<div class="clubs-list-page">
  <div v-for="club in curClubList.slice(clubPos,clubPos+8)" :key="club._id" :class="('Cname='+club['Club Name'] == search || club['Club Name'].toLowerCase() == search.toLowerCase()) ? 'club-panel club-panel-searched':'club-panel'" @click="clubPanelClick(club._id)">
    <div class="club-header">
      <div class="club-name-rating-row">
        <div class="club-name"> 
          {{club['Club Name']}}
          <i  v-if="club['Verified']" class="fas fa-check-circle"></i>
        </div>
        <div class="club-rating">Rating:&nbsp;{{clubRating(club['Reviews'])}}</div>
      </div>
      <div class="category"> 
        ({{club['Category']}}) 
        <div class="club-name-underline"></div>
      </div> 
    </div>
    <span 
      v-html="(club['Club Summary'].length > 470 ? club['Club Summary'].substring(0,470) + '...' : club['Club Summary'])" class="club-summary">
    </span>
  </div>

  <div v-if="!curClubList.length && fullClubList.length">
    Sorry, no clubs match your input
  </div>
  <div class="change-page-buttons-div">
    <button @click="goPrevPage" :class="clubPos > 0 ? 'change-page':'change-page change-grayed'"> Prev Page</button>
    <button @click="goNextPage" :class="(clubPos+8 < curClubList.length) ? 'change-page':'change-page change-grayed'"> Next Page</button>
  </div>
</div>
</template>

<script>
export default {
  name: 'Clubs',
  props: ['fullClubList', 'search', 'category'],
  data(){
    return{
      curClubList: [],
      clubPos: 0,
    }
  },
  computed:{
    updatedCategory(){
      if(this.category.length == 2)
        return this.category[0]+'/'+this.category[1];
      else
        return this.category;
    }
  },
  watch:{
    fullClubList(){
      this.updateClubList();
    },
    search(){
      this.updateClubList();
    },
    category(){
      this.updateClubList();
    },
  },
  mounted(){
    this.updateClubList();
  },
  methods: {
    clubPanelClick(id){
      this.$router.push({
          name: "ClubPage",
          params: { clubId: id },
        });
    },
    updateClubList(){
      if(!this.fullClubList.length)
        return;
      this.clubPos = 0;
      let exactName = false;
      let searchLower = this.search.toLowerCase();
      if(this.search.includes('Cname=')){
        exactName = true;
        searchLower = searchLower.substring(6);
      }

      if(exactName && !this.updatedCategory.length){
        let club = this.fullClubList.filter(a => a['Club Name'].toLowerCase() == searchLower)[0];
        this.curClubList = this.fullClubList.filter(a => {
          if(a['Club Name'] == club['Club Name']) return false;
          let categories = club['Category'].split(', ');
          for(let c of categories){
            if(a['Category'].split(', ').some(cat => cat == c))
              return true;
          }
          return false;
        });
        this.shuffleArray(this.curClubList);
        this.curClubList.unshift(club);
        this.curClubList = this.curClubList.slice(0,60);
      }
      else if(!this.updatedCategory.length){
        this.curClubList = this.fullClubList.filter(a => a['Club Name'].toLowerCase().indexOf(searchLower) != -1).slice(0,60);
      }
      else{
        this.curClubList = this.fullClubList.filter(a => a['Category'].split(', ').some(cat => cat == this.updatedCategory)).sort((a,b) => {
          let aS = a['Club Name'].toLowerCase().indexOf(searchLower), bS = b['Club Name'].toLowerCase().indexOf(searchLower);
          return (aS == -1 ? (bS == -1 ? 0 : 1) : (bS == -1 ? -1 : aS - bS));
        }).slice(0,60);
      }
      let i = 0;
      while(this.curClubList.length < 60 && i < 3){
        if(!this.curClubList[i])
          return;
        let categories = (this.curClubList[i])['Category'].split(', ');
        this.fullClubList.filter(a => {
          if(this.curClubList.some(el => el._id == a._id))
            return false;
          for(let c of categories){
            if(a['Category'].split(', ').some(cat => cat == c))
              return true;
          }
          return false;
        }).slice(0, 60 - this.curClubList.length).forEach(element => {
          this.curClubList.push(element);
        });
        i++;
      }
      this.showPanelsEffect(300);
    },
    showPanelsEffect(delay){
      for(let i = 0; i < 8; i++){
        setTimeout(() => {
          if(document.getElementsByClassName('club-panel')[i])
            document.getElementsByClassName('club-panel')[i].style.opacity = 1;
        }, i*50+delay);
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    goPrevPage(){
      this.clubPos -= 8;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.showPanelsEffect(300);
    },
    goNextPage(){
      this.clubPos += 8;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.showPanelsEffect(300);
    },
    clubRating(reviews){
      let sum = 0;
      if(!reviews)
        return '0.0';
      reviews.forEach(element => {
        sum += element.rating;
      }); 
      return parseFloat((sum) / (reviews.length)).toFixed(1);
    }
  },
}
</script>

<style scoped>
.clubs-list-page{
  margin-top: 20px;
  background: transparent;
  min-height: 90vh;
  margin-bottom: 50px;
  padding: 0 6px;
}
.club-panel{
  background: #ffffff26; 
  margin: 0 auto; 
  padding: 70px 20px;
  cursor: pointer;
  border-bottom: 1px solid #c5c5c5;
  max-width: 1000px;
  opacity: 0;
  transition: .4s all;
  text-align: left;
}
.club-panel:hover .club-name-underline{
  width: 100%;
}
.club-panel-searched{
  border-bottom: 2px solid var(--primary-color);
}
.club-header{
  margin-bottom: 10px;
}
.club-name-rating-row{
  display: flex;
  justify-content: space-between;
}
.club-name{
  font-weight: 600;
  font-size: 24px;
}
.club-name-underline{
  width: 0;
  height: 0;
  border-bottom: 3px solid var(--orange-accent);
  transition: .5s all;
  transition-delay: 0s;
}
.category{
  font-size: 12px;
  font-style: italic;
  width: fit-content;
}
.club-rating{
  float: right;
  padding: 5px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  font-weight: 700;
  height: fit-content;
  margin-left: 10px;
}
.club-summary{
  font-size: 15px;
}
.change-page-buttons-div{
  margin-top: 20px;
}
.change-page{
  background: white;
  color: black;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: .3s all;
  width: 90px;
  border: 1px solid gray;
}
.change-page:hover{
  background: #f0f0f0;
}
.change-grayed{
  pointer-events: none;
  cursor: initial;
  background: lightgray;
}
.fa-check-circle{
  color: var(--primary-color);
}
</style>
